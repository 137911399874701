<template>
  <div :style="`width: ${width}%; transition: all .90s ease`" class="h-3 bg-gray-200 animate-pulse "></div>
</template>

<script>
export default {
  name: "ItemSkeleton",
  props: {
    width: { type: Number },
  },
};
</script>