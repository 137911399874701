<template>
  <div class="flex flex-col">
    <small class="text-gray-700">{{ label }}</small>
    <div class="flex items-center mt-2">
      <input
        v-bind="$attrs"
        v-bind:value="value"
        v-on="inputListeners"
        class="w-full transition duration-300 text-sm text-gray-700 rounded-lg border-2 hover:border-blue-300 focus:border-blue-500 bg-gray-50 px-3 py-2 focus:outline-none"
        :type="type"
      />
      <button
        @click="$emit('delete')"
        class="flex focus:outline-none hover:text-red-400 transition duration-300 items-center p-3 text-gray-400"
      >
        <i class="bx bx-trash flex items-center"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputApp",
  props: {
    type: { type: String },
    value: { type: String },
    label: { type: String },
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        input: function (event) {
          vm.$emit("input", event.target.value);
        },
      });
    },
  },
};
</script>
