export default {
    namespaced: true,
    state: {
        template: {
            basic: {
                fields: [
                    { value: '' }
                ],
            }
        }
    },
    mutations: {
        getTemplate(state, data) {
            state.template = data
        },
        deleteBasicFields(state, i) {
            state.template.basic.fields.splice(i, 1)
        }
    }
}