<template>
  <div>
    <ItemSkeleton
      class="mt-2"
      v-for="(item, i) in skeleton"
      :key="i"
      :width="item"
    />
  </div>
</template>

<script>
import ItemSkeleton from "./ItemSkeleton";
export default {
  components: {
    ItemSkeleton,
  },
  data() {
    return {
      skeleton: [],
    };
  },
  mounted() {
    this.getRandomSkeleton();
  },
  methods: {
    getRandomSkeleton() {
      for (let index = 0; index < 10; index++) {
        let random = Math.random() * (100 - 25 + 25);
        this.skeleton.push(random);
      }
      setInterval(() => {
        this.skeleton = [];
        for (let index = 0; index < 10; index++) {
          let random = Math.random() * (100 - 25 + 25);
          this.skeleton.push(random);
        }
      }, 6000);
    },
  },
};
</script>