import firebase from 'firebase/app'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: "AIzaSyBkrIzLIWfYENjB9VazN-iy0ybUeHIoqNc",
    authDomain: "signature-37212.firebaseapp.com",
    projectId: "signature-37212",
    storageBucket: "signature-37212.appspot.com",
    messagingSenderId: "282358987088",
    appId: "1:282358987088:web:2afa717380702987c49455"
};

firebase.initializeApp(firebaseConfig);
export default firebase;

export const storage = firebase.storage()