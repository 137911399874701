<template>
  <div class="w-full">
    <div class="signature">
      <table id="signature" style="border: none; border-collapse: separate">
        <colgroup>
          <col width="20" />
          <col width="100%" />
        </colgroup>
        <tbody>
          <tr style="height: 62.25pt; box-sizing: content-box;">
            <td
              style="
                vertical-align: top;
                padding: 5px 20px;
                overflow: hidden;
                vertical-align: middle;
              "
            >
              <p
                dir="ltr"
                style="
                  line-height: 1.8px;
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                  margin-right: auto;
                  margin-left: auto;
                "
              >
                <span
                  style="
                    font-family: Avenir;
                    color: rgb(0, 0, 0);
                    background-color: transparent;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                    margin-bottom: 0px;
                  "
                  ><span
                    style="
                      border: none;
                      display: inline-block;
                      overflow: hidden;
                      width: 100px;
                      height: 100px;
                      border-radius: 50%;
                      margin-left: auto;
                      margin-right: auto;
                    "
                    ><img
                      :src="template.basic.image"
                      width="100"
                      height="100"
                      style="
                        margin-left: 0px;
                        margin-top: 0px;
                        object-fit: cover;
                      "
                      class="text-transparent CToWUd"
                      alt="Employee Image" /></span
                ></span>
              </p>
              <p
                dir="ltr"
                style="
                  line-height: 1.8px;
                  margin-top: 10px;
                  margin-bottom: 0pt;
                  margin-left: auto;
                  margin-right: auto;
                "
              >
                <span
                  style="
                    font-family: Avenir;
                    color: #fff;
                    background-color: transparent;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                  "
                  ><span
                    style="
                      border: none;
                      display: flex;
                      width: 90px;
                      height: 60%;
                      object-fit: scale-down;
                      margin-top: 0px;
                      width: 100%;
                    "
                  >
                    <img                      
                      src="https://cdn.mercandu.com/signatures/assets/static/logo.png"
                      style="
                        margin-left: 0px;
                        margin-top: 0px;
                        width: 100px;
                        object-fit: scale-down;
                        margin: auto;
                        height: auto;
                        text-color: white;
                      "
                    />
                  </span>
                </span>
              </p>
            </td>
            <td
              style="
                vertical-align: middle;
                overflow: hidden;
                line-height: 150px;
                border-left: 1px solid #D6D6D6;
                border-image: url(https://cdn.mercandu.com/signatures/assets/static/line.png) 10 repeat;
                -moz-border-image: url(https://cdn.mercandu.com/signatures/assets/static/line.png) 10 repeat;
                -webkit-border-image: url(https://cdn.mercandu.com/signatures/assets/static/line.png) 10 repeat;
                -o-border-image: url(https://cdn.mercandu.com/signatures/assets/static/line.png) 10 repeat;
                padding-left: 25px;
              "
            >
              <p
                v-for="(basic, i) in template.basic.fields"
                :key="i"
                dir="ltr"
                style="margin-top: 0pt; margin-bottom: 0pt; display: flex;"
                :style="
                  i == 0
                    ? 'line-height:1.2; margin-bottom: 1px; font-size:18px;text-transform:uppercase;'
                    : i == 1
                    ? 'line-height:1.4; margin-bottom: 16px'
                    : 'line-height:1.5; margin-top: 3px;'
                "
              >
                <img
                  v-if="basic.icon != '' && basic.value != ''"
                  width="15px"
                  height="15px"
                  :src="basic.icon"                  
                  style="margin-right: 5px; width: 15px; height: 15px; object-fit: contain"
                  alt="Signature Icon"
                />
                <span
                  style="
                    font-family: Avenir;
                    color: #5f5f5f;
                    background-color: transparent;
                    vertical-align: baseline;
                    white-space: pre-wrap;
                  "
                  :style="
                    i == 0
                      ? 'font-weight:700; color: #444444; font-family: Avenir Next'
                      : i == 1
                      ? 'font-weight:700; text-transform: uppercase; font-size: .68rem; letter-spacing: .8px'
                      : 'font-size: 0.63rem; letter-spacing: .5px; font-weight: 500; color: #444'
                  "
                  >{{ basic.value }}</span
                >
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div></div>
    <div class="flex justify-end mt-5">
      <ButtonApp class="mr-2" @click="CopySignature">Copy Signature</ButtonApp>
      <ButtonApp @click="copyHTML">Copy HTML</ButtonApp>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ButtonApp from "@/components/Utils/ButtonApp";
export default {
  name: "Signature",
  components: {
    ButtonApp,
  },
  data() {
    return {
      signA: null,
    };
  },
  computed: {
    ...mapState("Template", ["template"]),
  },
  methods: {
    copyHTML() {
      let sign = document.querySelector(".signature table");
      this.$copyText(this.nodeToString(sign));
      this.$toasts.success("HTML pure has been copied", {
        duration: 4000,
      });
    },
    CopySignature() {
      var range = document.createRange();
      range.selectNode(document.getElementById("signature"));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      this.$toasts.success("Signature has been copied", {
        duration: 4000,
      });
    },
    nodeToString(node) {
      var tmpNode = document.createElement("div");
      tmpNode.appendChild(node.cloneNode(true));
      var str = tmpNode.innerHTML;
      tmpNode = node = null;
      return str;
    },
  },
};
</script>