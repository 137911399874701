<template>
  <div
    class="h-screen flex-col flex items-center justify-center w-full lg:p-10 lg:overflow-auto"
  >
    <div class="lg:shadow-md p-3 w-full">
      <Skeleton />
      <div class="mt-8">
        <Signature />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "./Preview/Skeleton";
import Signature from "./Signature";

export default {
  components: {
    Skeleton,
    Signature,
  },
};
</script>