<template>
  <div class="bg-white p-10 w-full rounded-xl">
    <div class="font-semibold mb-5">Signature details</div>
    <div v-if="!editView">
      <div class="flex items-center">
        <div class="mr-5">
          <div
            class="w-32 h-32 flex justify-center items-center relative rounded-full overflow-hidden bg-gray-200"
          >
            <div
              v-if="loadingPhoto"
              class="absolute opacity-50 bg-black w-full h-full flex justify-center items-center rounded-full"
            >
              <i class="bx bx-loader-alt text-white bx-sm bx-spin"></i>
            </div>
            <img
              :src="template.basic.image"
              class="w-full h-full object-cover text-transparent"
              alt="Signature Picture"
            />
          </div>
        </div>
        <div class="w-full">
          <!-- <InputApp
            label="Image url"
            @delete="(template.basic.image = ''), clearPhoto()"
            v-model="template.basic.image"
          ></InputApp> -->
          <div class="w-full rounded-lg overflow-hidden">
            <div
              :style="'width:' + 100 + '%; transition: all .25s ease'"
              class="bg-green-500 h-full"
            ></div>
          </div>
          <input
            style="display: none"
            type="file"
            ref="fileInput"
            accept="image/*"
            @change="uploadFile"
          />
          <ButtonApp @click="$refs.fileInput.click()" class="mt-2">
            {{ !loadingPhoto ? "Upload Image" : "Loading" }}
          </ButtonApp>
        </div>
      </div>
      <div class="grid lg:grid-cols-2">
        <div class="p-1" v-for="(item, i) in template.basic.fields" :key="i">
          <!-- Se iteran los datos dinamicos para la plantilla -->
          <InputApp
            @delete="item.value = ''"
            :label="item.label"
            v-model="item.value"
            :type="item.type"
          />
        </div>
      </div>
      <!-- <ButtonApp class="mt-5" block>Custom field</ButtonApp> -->
    </div>
    <div v-if="editView" class="w-full lg:h-96">
      <cropper
        class="cropper"
        :src="cropImage"
        stencil-component="circle-stencil"
        :stencil-props="{
          aspectRatio: 12 / 12,
        }"
        @change="photo"
      />
      <div class="flex justify-end mt-4">
        <ButtonApp class="mr-2" color="gray" @click="cancelPhoto"
          >Cancel</ButtonApp
        >
        <ButtonApp @click="cropPhoto">Crop photo</ButtonApp>
      </div>
    </div>
  </div>
</template>

<script>
import InputApp from "@/components/Utils/InputApp";
import ButtonApp from "@/components/Utils/ButtonApp";
import { mapMutations, mapState } from "vuex";
import { storage } from "@/firebase";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Compressor from "compressorjs";

export default {
  name: "Home",
  components: { InputApp, ButtonApp, Cropper },
  computed: {
    ...mapState("Template", ["template"]),
  },
  data() {
    return {
      previewImages: [],
      loadingPhoto: false,
      cropImage: null,
      editView: false,
      viewEdit: null,
      percent: 0,
      /* In mb */
      maxSizeImage: 2,
    };
  },
  methods: {
    ...mapMutations("Template", ["deleteBasicFields"]),
    del(i) {
      this.deleteBasicFields(i);
    },
    cancelPhoto() {
      this.previewImages = [];
      this.editView = false;
      this.cropImage = "";
      this.viewEdit = "";
      this.loadingPhoto = false;
    },
    clearPhoto() {
      this.previewImages = [];
      this.cropImage = "";
      this.viewEdit = "";
    },
    uploadFile(e) {
      this.clearPhoto();
      var imageFile = e.target.files[0];
      if (parseInt(e.target.files[0].size / 10000) > this.maxSizeImage * 1000) {
        this.$toasts.error("The image has a weight greater than 10 MB.", {
          duration: 4000,
        });
      } else {
        /* console.log(e.target.files[0].size) */
        let that = this;
        new Compressor(imageFile, {
          quality: 0.2,
          success(result) {
            that.showImage(result);
          },
        });
        this.loadingPhoto = true;
        this.cancelPhoto();
      }
    },
    showImage(file) {
      let reader = new FileReader();
      reader.onload = async (e) => {
        await this.previewImages.push({ src: e.target.result, file: file });
        this.cropImage = e.target.result;
        this.editView = true;
      };
      reader.readAsDataURL(file);
    },
    photo({ canvas }) {
      var resizedCanvas = document.createElement('canvas');
      var resizedContext = resizedCanvas.getContext('2d');

      resizedCanvas.height = 300;
      resizedCanvas.width = 300;

      resizedContext.drawImage(canvas, 0, 0, 300, 300);
      this.viewEdit = resizedCanvas.toDataURL('image/jpeg');
    },
    cropPhoto() {
      this.editView = false;
      this.uploadAvatar(this.viewEdit);
    },
    async base64toBlob(base64) {
      var binary = atob(base64.split(",")[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {
        type: "image/jpeg",
      });
    },
    async uploadAvatar(base64) {
      const blob = await this.base64toBlob(base64);

      const formData = new FormData();
      formData.append("file", blob, "avatar.jpeg");

      this.loadingPhoto = true;
      const response = await fetch(process.env.VUE_APP_UPLOAD_ENDPOINT, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      this.loadingPhoto = false;
      this.template.basic.image = data.fileLocation;
      this.percent = 0;
    },
  },
};
</script>

<style lang="less">
.vue-advanced-cropper__foreground {
  background: white !important;
  background-color: black;
}
.vue-preview-result__image {
  object-fit: contain !important;
}
</style>
