var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"signature"},[_c('table',{staticStyle:{"border":"none","border-collapse":"separate"},attrs:{"id":"signature"}},[_vm._m(0),_c('tbody',[_c('tr',{staticStyle:{"height":"62.25pt","box-sizing":"content-box"}},[_c('td',{staticStyle:{"vertical-align":"middle","padding":"5px 20px","overflow":"hidden"}},[_c('p',{staticStyle:{"line-height":"1.8px","margin-top":"0pt","margin-bottom":"0pt","margin-right":"auto","margin-left":"auto"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-family":"Avenir","color":"rgb(0, 0, 0)","background-color":"transparent","vertical-align":"baseline","white-space":"pre-wrap","margin-bottom":"0px"}},[_c('span',{staticStyle:{"border":"none","display":"inline-block","overflow":"hidden","width":"100px","height":"100px","border-radius":"50%","margin-left":"auto","margin-right":"auto"}},[_c('img',{staticClass:"text-transparent CToWUd",staticStyle:{"margin-left":"0px","margin-top":"0px","object-fit":"cover"},attrs:{"src":_vm.template.basic.image,"width":"100","height":"100","alt":"Employee Image"}})])])]),_vm._m(1)]),_c('td',{staticStyle:{"vertical-align":"middle","overflow":"hidden","line-height":"150px","border-left":"1px solid #D6D6D6","border-image":"url(https://cdn.mercandu.com/signatures/assets/static/line.png) 10 repeat","-moz-border-image":"url(https://cdn.mercandu.com/signatures/assets/static/line.png) 10 repeat","-webkit-border-image":"url(https://cdn.mercandu.com/signatures/assets/static/line.png) 10 repeat","-o-border-image":"url(https://cdn.mercandu.com/signatures/assets/static/line.png) 10 repeat","padding-left":"25px"}},_vm._l((_vm.template.basic.fields),function(basic,i){return _c('p',{key:i,staticStyle:{"margin-top":"0pt","margin-bottom":"0pt","display":"flex"},style:(i == 0
                  ? 'line-height:1.2; margin-bottom: 1px; font-size:18px;text-transform:uppercase;'
                  : i == 1
                  ? 'line-height:1.4; margin-bottom: 16px'
                  : 'line-height:1.5; margin-top: 3px;'),attrs:{"dir":"ltr"}},[(basic.icon != '' && basic.value != '')?_c('img',{staticStyle:{"margin-right":"5px","width":"15px","height":"15px","object-fit":"contain"},attrs:{"width":"15px","height":"15px","src":basic.icon,"alt":"Signature Icon"}}):_vm._e(),_c('span',{staticStyle:{"font-family":"Avenir","color":"#5f5f5f","background-color":"transparent","vertical-align":"baseline","white-space":"pre-wrap"},style:(i == 0
                    ? 'font-weight:700; color: #444444; font-family: Avenir Next'
                    : i == 1
                    ? 'font-weight:700; text-transform: uppercase; font-size: .68rem; letter-spacing: .8px'
                    : 'font-size: 0.63rem; letter-spacing: .5px; font-weight: 500; color: #444')},[_vm._v(_vm._s(basic.value))])])}),0)])])])]),_c('div'),_c('div',{staticClass:"flex justify-end mt-5"},[_c('ButtonApp',{staticClass:"mr-2",on:{"click":_vm.CopySignature}},[_vm._v("Copy Signature")]),_c('ButtonApp',{on:{"click":_vm.copyHTML}},[_vm._v("Copy HTML")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('colgroup',[_c('col',{attrs:{"width":"20"}}),_c('col',{attrs:{"width":"100%"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticStyle:{"line-height":"1.8px","margin-top":"10px","margin-bottom":"0pt","margin-left":"auto","margin-right":"auto"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-family":"Avenir","color":"#fff","background-color":"transparent","vertical-align":"baseline","white-space":"pre-wrap"}},[_c('span',{staticStyle:{"border":"none","display":"flex","width":"100%","height":"60%","object-fit":"scale-down","margin-top":"0px"}},[_c('img',{staticStyle:{"margin-left":"0px","margin-top":"0px","width":"100px","object-fit":"scale-down","margin":"auto","height":"auto","text-color":"white"},attrs:{"src":"https://cdn.mercandu.com/signatures/assets/static/logo.png"}})])])])}]

export { render, staticRenderFns }