import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './tailwind.css'
import '@/firebase'
import VueClipboard from 'vue-clipboard2'
import VueMyToasts from 'vue-my-toasts'
import 'vue-my-toasts/dist/vue-my-toasts.css'
import toast from '@/components/Utils/Toast'

Vue.use(VueMyToasts, { component: toast, options: { width: '400px', position: 'bottom-right', padding: '1rem' } })

Vue.use(VueClipboard)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
