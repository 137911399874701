/* Se importa el template por defecto*/
import template from '../../template.json'
export default {
    getConfiguration() {
        /* Este es el template por defecto */
        /* let data = await template */
        return template
        /* console.log(template) */
    }
}
