import Vue from 'vue'
import Vuex from 'vuex'
import Template from './Template/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Template
  }
})
