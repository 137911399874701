<template>
  <div id="app">
    <div class="grid lg:grid-cols-2">
      <div
        class="bg-blue-50 pb-5 lg:h-screen lg:overflow-y-auto overflow-x-hidden"
      >
        <div class="lg:mx-10 mx-0 flex items-center justify-center h-full p-4">
          <router-view />
        </div>
      </div>
      <Preview />
    </div>
  </div>
</template>

<script>
import Template from "@/utils/Template/index";
import Preview from "@/components/Preview";
import { mapMutations } from "vuex";
export default {
  components: {
    Preview,
  },
  mounted() {
    /* Obtiene la configuración inicial */
    this.getTemplate(Template.getConfiguration());
  },
  methods: {
    ...mapMutations("Template", ["getTemplate"]),
  },
};
</script>

<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  @apply w-2;
}

::-webkit-scrollbar-track {
  @apply bg-white;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-200;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-300;
}
</style>
