<template>
  <button
    v-on="$listeners"
    :class="[
      { 'w-full': block },
      color == 'blue'
        ? `bg-blue-500 hover:bg-blue-600`
        : `bg-gray-500 hover:bg-gray-600`,
    ]"
    class="items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ButtonApp",
  props: {
    block: { type: Boolean },
    color: { type: String, default: "blue" },
  },
};
</script>