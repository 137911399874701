<template>
  <li
    :class="[
      `bg-${color}-500`,
      {
        'mb-2': position.includes('top'),
        'mt-2': position.includes('bottom')
      }
    ]"
    class="relative flex items-center p-3 overflow-hidden leading-none text-white bg-gray-800 rounded-lg cursor-pointer select-none lg:inline-flex"
    role="alert"
    @click="$emit('remove')"
  >
    <div
      class="absolute bottom-0 left-0 h-1 bg-white opacity-25"
      :style="{ width: percentageElapsed + '%' }"
    />
    <span class="flex-1 mr-2 font-semibold text-left text-white">{{ message }}</span>
  </li>
</template>

<script>
import ToastMixin from '@/mixins/ToastMixin'
import TimerMixin from '@/mixins/TimerMixin'
export default {
  name: 'ToastComponent',
  mixins: [ToastMixin, TimerMixin],
  props: {
    badge: {
      type: [String, Boolean],
      required: false,
      default: false
    }
  },
  computed: {
    color() {
      switch (this.type) {
        case 'base':
          return 'blue'
        case 'warning':
          return 'orange'
        case 'error':
          return 'red'
        case 'success':
          return 'green'
        default:
          return 'blue'
      }
    }
  }
}
</script>

<style scoped>
svg.bottom-middle {
  transform: rotate(90deg);
}
svg.top-middle {
  transform: rotate(-90deg);
}
svg.bottom-left {
  transform: rotate(180deg);
}
svg.top-left {
  transform: rotate(180deg);
}
</style>